<template>
  <v-menu :close-on-content-click="false" bottom left offset-y origin="top right" transition="scale-transition">
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="md-ml-2" min-width="0" text v-bind="attrs" v-on="on">
        <v-badge color="red" overlap bordered :value="unreadCount" :content="unreadCount">
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list class="notification-list" subheader>
      <v-subheader>Bildirimler</v-subheader>

      <v-list-item v-for="notification in notifications" :key="notification.id" @click="markAsRead(notification)">
        <router-link style="display: flex;width: 100%" :to="notification.link" v-if="notification.link != null">
          <v-list-item-content>
            <p>{{ notification.message }}</p>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="notification.markRead ? 'red' : 'grey'">done</v-icon>
          </v-list-item-icon>
        </router-link>
        <div v-else style="display: flex; width: 100%">
          <v-list-item-content>
            <p>{{ notification.message }}</p>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="notification.markRead ? 'red' : 'grey'">done</v-icon>
          </v-list-item-icon>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "notification",
  methods: {
    ...mapActions("notification", ["fetchNotifications", "markAsRead"])
  },
  computed: {
    ...mapGetters("notification", ["notifications"]),
    unreadCount() {
      let counter = 0;
      this.notifications.filter(n => {
        if (!n.markRead) counter++;
      });
      return counter;
    }
  },
  created() {
    this.fetchNotifications();
  }
};
</script>

<style>
.notification-list {
  max-height: 350px;
  overflow-y: auto;
  width: 350px;
}
</style>
