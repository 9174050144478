<template>
  <div>
    <v-app-bar id="app-bar" flat app clipped-right color="white" height="75">
      <v-btn class="mr-3" elevation="2" fab small @click="setDrawer(!drawer)">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>

      <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="$t('route.' + $route.name)" />

      <v-spacer />

      <SwitchCourseCategory />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="md-ml-2" min-width="0" v-bind="attrs" v-on="on" @click="onClickAnnouncements">
            <v-icon>
              mdi-newspaper
            </v-icon>
          </v-btn>
        </template>
        <span>Duyurular</span>
      </v-tooltip>

      <notification></notification>

      <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ on: menu }">
          <v-btn class="md-ml-2" min-width="0" v-on="{ ...menu }" text>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="'/profile/' + currentUser.id">
            <v-list-item-title>{{ $t("user.profile") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/profile/settings">
            <v-list-item-title>{{ $t("common.settings") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Çıkış Yap</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
// Components
import Notification from "@/components/Notification";
import SwitchCourseCategory from "@/components/SwitchCourseCategory";

// Utilities
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    Notification,
    SwitchCourseCategory
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapGetters("auth", ["currentUser"])
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    ...mapActions("announcement", ["fetchCurrentAnnouncements"]),
    ...mapMutations("announcement", ["show"]),
    logout() {
      this.$store.dispatch("auth/logout");
    },
    async onClickAnnouncements() {
      await this.fetchCurrentAnnouncements();
      this.show();
    }
  }
};
</script>
