<template>
  <v-menu :close-on-content-click="false" bottom left offset-y origin="top right" transition="scale-transition">
    <template v-slot:activator="{ attrs, on }">
      <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-1">
        <v-icon left color="white">refresh</v-icon>
        Sınıf Değiştir
      </v-btn>
    </template>

    <v-list class="notification-list" subheader>
      <v-subheader>Sınıflar</v-subheader>

      <v-list-item
        v-for="courseCategory in courseCategories"
        :key="courseCategory.id"
        @click="changeCourseCategory(courseCategory)"
      >
        <v-list-item-content>
          {{ courseCategory.name }}
        </v-list-item-content>
        <v-list-item-icon v-if="courseCategory.id === currentCourseCategory.id">
          <v-icon color="primary">done</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { StorageService, COURSE_CATEGORY } from "@/common/storage.service";

export default {
  mounted() {
    this.fetchCourseCategories("MinimalProjection");
  },
  computed: {
    ...mapState("course", ["courseCategories"]),
    currentCourseCategory() {
      return StorageService.getItem(COURSE_CATEGORY);
    }
  },
  methods: {
    ...mapActions("course", ["fetchCourseCategories"]),

    changeCourseCategory(courseCategory) {
      StorageService.saveItem(COURSE_CATEGORY, courseCategory);
      location.reload();
    }
  }
};
</script>

<style></style>
